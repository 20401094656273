import { useConfirm } from '#imports';
import { PointType } from '~/models';
import PointTypeAPI from '~/api/PointTypeAPI';

export const usePointTypeDestroy = async (projectSlug: string, typeId: number): Promise<void> => {
  const typeRepo = useRepo(PointType);
  const pointType = typeRepo.find(typeId);
  if (!pointType) {
    logger().warn(`Destroy undefined type ${typeId}`);
    return;
  }
  const { t } = useNuxtApp().$i18n;
  const confirmed = await useConfirm(t('project.confirm.destroyType', { name: pointType.name }));
  if (!confirmed) {
    return;
  }
  try {
    pointType._deleting = true;
    await PointTypeAPI.destroy(projectSlug, typeId);
    typeRepo.destroy(typeId);
  } catch (e) {
    logger().error(e);
  } finally {
    pointType._deleting = false;
  }
};
