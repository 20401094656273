<script setup lang="ts">
import { computedAsync } from '@vueuse/core';
import type { ButtonSize } from '#ui/types';
import { PointTag } from '~/models';
import { getIconSvg } from '~/composables/map/icon';
import { useTagIconStyle } from '~/composables';

const props = defineProps({
  tag: { type: PointTag, required: true },
  clearable: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  clickable: { type: Boolean, default: false },
  block: { type: Boolean, default: false },
  size: { type: String, default: 'xs' },
});
defineSlots<{
  default(): any
  actions(): any
}>();
const emit = defineEmits(['clear', 'click']);

const style = computed(() => useTagIconStyle(props.tag.settings));
const icon = computedAsync(async () => props.tag.settings.icon
  ? await getIconSvg(`i-bi-${props.tag.settings.icon}`)
  : null,
null,
);
function onClick() {
  if (props.clickable) {
    emit('click');
  }
}
</script>

<template>
  <UButton
    class="point-tag"
    :class="[clickable ? 'cursor-pointer' : 'cursor-default']"
    :title="tag.title"
    :block="block"
    :disabled="disabled"
    :size="size as ButtonSize"
    :style="style"
    @click="onClick"
  >
    <template v-if="icon" #leading>
      <span v-html="icon" />
    </template>
    <span class="truncate flex-grow ml-1">{{ tag.name }}</span>
    <template v-if="clearable || $slots.actions" #trailing>
      <span class="point-tag-actions">
        <slot name="actions" />
        <UButton
          v-if="clearable"
          :padded="false"
          :loading="tag._deleting"
          variant="link"
          color="white"
          icon="i-bi-x"
          size="xs"
          @click.stop="emit('clear')"
        />
      </span>
    </template>
  </UButton>
</template>

<style lang="postcss" scoped>
.point-tag {
  &:hover {
    .point-tag-actions {
      @apply opacity-100
    }
  }
}

.point-tag-actions {
  line-height: 1;
  @apply opacity-40 transition-opacity flex items-center -mr-1 ml-1
}
</style>
