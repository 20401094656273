import { useConfirm, useEmit } from '#imports';
import { Point, PointDeliveryPrice } from '~/models';
import PointAPI from '~/api/PointAPI';

export const usePointTrash = async (projectSlug: string, pointId: number) => {
  const pointRepo = useRepo(Point);
  const point = pointRepo.find(pointId);
  if (!point) {
    logger().warn(`Destroy undefined point ${pointId}`);
    return;
  }
  const { t } = useNuxtApp().$i18n;
  const confirmed = await useConfirm({ text: t('project.confirm.destroyPoint', { name: point.name }) });
  if (!confirmed) {
    return;
  }
  try {
    point._deleting = true;
    await PointAPI.trash(projectSlug, pointId);
    pointRepo.destroy(pointId);
    useEmit('point:deleted', point.id);
  } catch (e) {
    logger().error(e);
  } finally {
    point._deleting = false;
  }
};

export const resolveDeliveryPrice = (pointId: number, distanceInMeter: number): PointDeliveryPrice | null => {
  const deliveryPrices = useRepo(PointDeliveryPrice)
    .orderBy('distance')
    .where('pointId', pointId)
    .get();
  for (const price of deliveryPrices) {
    if ((price.distance * 1000) >= distanceInMeter) {
      return price;
    }
  }
  return null;
};
