<script setup lang="ts">
import { PointTag, Project } from '~/models';
import { isBackendValidationError, useTagDelete } from '#imports';
import PointTagAPI, { type TagSaveRequest } from '~/api/PointTagAPI';
import type { BackendErrors } from '~/types';

const emit = defineEmits(['toggle']);
const props = defineProps<{
  tag: PointTag
  isAttached: boolean
  canEditTags: boolean
  canDeleteTags: boolean
}>();
const tagRepo = useRepo(PointTag);
const state = reactive({
  editing: false,
  saving: false,
});
const form = shallowRef();
const formData = ref<PointTag>(new PointTag());
function toggleTag() {
  emit('toggle');
}
function edit() {
  state.editing = true;
  formData.value = new PointTag(props.tag.$toJson());
}

async function save() {
  try {
    state.saving = false;
    const project = useRepo(Project).find(props.tag.projectId) as Project;
    const { data } = await PointTagAPI.save(project.slug, formData.value);
    tagRepo.save(data as object);
  } catch (e: any) {
    if (isBackendValidationError(e)) {
      const errors = e.response.data.errors as BackendErrors;
      setBackendErrors(form.value, errors);
    } else {
      logger().error(e);
    }
  } finally {
    state.saving = false;
    state.editing = false;
  }
}

async function destroy() {
  const project = useRepo(Project).find(props.tag.projectId) as Project;
  await useTagDelete(project.slug, props.tag.id);
}
</script>

<template>
  <div class="relative pl-7">
    <UButton
      class="absolute left-0 top-0"
      size="xs"
      variant="link"
      :color="isAttached ? 'green' : 'gray'"
      :icon="isAttached ? 'i-bi-check-square-fill' : 'i-bi-square'"
      @click="toggleTag"
    />
    <project-tag
      :tag="tag"
      clickable
      class="mb-1"
      @click="toggleTag"
    >
      <template #actions>
        <UButton
          v-if="canEditTags"
          :padded="false"
          variant="soft"
          size="2xs"
          color="blue"
          icon="i-heroicons-pencil-square-16-solid"
          :title="$t('project.edit')"
          :disabled="tag._deleting"
          @click.stop="edit"
        />
        <UButton
          v-if="canDeleteTags"
          :padded="false"
          variant="soft"
          size="2xs"
          color="red"
          icon="i-heroicons-trash"
          :title="$t('project.delete')"
          :disabled="state.saving"
          :loading="tag._deleting"
          @click.stop="destroy"
        />
      </template>
    </project-tag>
    <el-modal
      v-if="state.editing"
      :model-value="true"
      :transition="false"
      :name="`edit_tag_${tag.id}`"
    >
      <UForm
        ref="form"
        :state="formData"
        :validate-on="['submit']"
        @submit="save"
      >
        <UCard>
          <div class="space-y-2">
            <div class="flex items-center">
              <span class="mr-2 text-sm">{{ $t('tag.icon') }}:</span>
              <project-tag-icon
                v-model="formData.settings"
                :disabled="state.saving"
              />
            </div>
            <UFormGroup name="name">
              <UInput
                v-model="formData.name"
                size="xs"
                autocomplete="off"
                :placeholder="$t('tag.name')"
              />
            </UFormGroup>
            <UFormGroup name="description">
              <UInput
                v-model="formData.description as string"
                size="xs"
                autocomplete="off"
                :placeholder="$t('tag.desc')"
              />
            </UFormGroup>
          </div>
          <template #footer>
            <div class="flex items-center justify-end space-x-1">
              <UButton
                size="xs"
                variant="ghost"
                color="gray"
                :disabled="state.saving"
                @click.stop="state.editing = false"
              >
                {{ $t('project.cancel') }}
              </UButton>
              <UButton
                size="xs"
                icon="i-bi-check"
                :loading="state.saving"
                type="submit"
              >
                {{ $t('project.save') }}
              </UButton>
            </div>
          </template>
        </UCard>
      </UForm>
    </el-modal>
  </div>
</template>

<style scoped lang="postcss">
</style>
