<script setup lang="ts">
import { iconToSVG } from '@iconify/utils/lib/svg/build';
import { getIconData } from '@iconify/utils/lib/icon-set/get-icon';
import { type IconsCollection, importCollection } from '~/composables/map/icon';

type groupKey = 'bi' | 'mdi' | 'heroicons' | 'map' | 'ic';

const name = defineModel<string|undefined|null>('name', { required: false });
const props = withDefaults(defineProps<{
  groups?: groupKey[]
}>(), {
  groups: () => (['bi', 'mdi', 'heroicons', 'map', 'ic']),
});

const availableGroups: Record<groupKey, IconsCollection> = (await Promise.all(props.groups.map(group => importCollection(group))))
  .map((collection, index) => ([props.groups[index], collection])).reduce((res, [group, collection]) => {
    // @ts-ignore
    res[group] = collection;
    return res;
  }, {} as Record<groupKey, IconsCollection>);
const query = ref<string>('');
const availableIcons = computed(() => Object.values(availableGroups).reduce((res, group) => {
  let icons = Object.keys(group.icons.icons).filter(Boolean);
  if (query.value) {
    icons = icons.filter(name => name.toLowerCase().includes(query.value.toLowerCase()));
  }
  return Object.assign(res, {
    [group.icons.prefix]: {
      title: group.info.name,
      total: icons.length,
      icons,
    },
  });
}, {} as Record<groupKey, { title: string, total: number, icons: string[] }>));
const limit = reactive({
  bi: 30,
  mdi: 30,
  heroicons: 30,
  map: 30,
  ic: 30,
});

function getIconName(group: groupKey, name: string): string {
  return `i-${group}-${name}`;
}
function getIconSvg(group: groupKey, name: string): string {
  const iconData = getIconData(availableGroups[group].icons, name);
  if (!iconData) {
    return '';
  }
  const svg = iconToSVG(iconData, {
    width: 24,
    height: 24,
  });
  return `<svg width="${svg.attributes.width}" height="${svg.attributes.height}" viewBox="${svg.attributes.viewBox}">${svg.body}</svg>`;
}
function showMore(group: groupKey) {
  limit[group] += 30;
}
</script>

<template>
  <div class="px-1">
    <UInput v-model="query" size="xs" :placeholder="$t('project.icon.search')" class="mb-1" />
    <div
      v-for="(collection, key) in availableIcons"
      :key="key"
      class="collection-group"
    >
      <div class="collection-name">
        {{ collection.title }} ({{ collection.total }}):
      </div>
      <div class="collection-icons">
        <UButton
          v-for="icon in collection.icons.slice(0, limit[key])"
          :key="icon"
          :variant="getIconName(key, icon) === name ? 'solid' : 'soft'"
          :color="getIconName(key, icon) === name ? 'primary' : 'gray'"
          class="icon-name"
          @click="name = getIconName(key, icon)"
        >
          <span v-html="getIconSvg(key, icon)" />
        </UButton>
      </div>
      <UButton
        v-if="limit[key] < collection.total"
        block
        size="xs"
        variant="outline"
        @click="showMore(key)"
      >
        {{ $t('project.icon.load') }}
      </UButton>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.collection-group {
  @apply mb-1
}

.collection-name {
  @apply px-1 py-0.5 bg-gray-100 dark:bg-gray-600 mb-1
}

.collection-icons {
  @apply flex flex-wrap items-center
}

.icon-name {
  &:hover {
    transform: scale(1.7);
  }
  @apply mb-0.5 mr-0.5 transition-all
}
</style>
