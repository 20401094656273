<script setup lang="ts">
import { PointType, Project } from '~/models';
import { usePointFiltersStore } from '#imports';

const project = inject<Project>(PROVIDE_PROJECT) as Project;
const pointFilters = usePointFiltersStore();
const pointTypes = computed<PointType[]>(
  () => useRepo(PointType)
    .where('projectId', project.id)
    .has('productCategories')
    .with(
      'productCategories',
      q => q
        .whereHas('products', q => q.has('prices'))
        .with('products',
          q => q.has('prices')
            .with('prices')
            .with('fields', q => q.orderBy('order'))
            .orderBy('order'),
        )
        .orderBy('order'),
    )
    .get(),
);
const opened = reactive<{ types: number[], categories: number[] }>({
  types: [],
  categories: [],
});
if (pointTypes.value.length) {
  opened.types.push(pointTypes.value[0].id);
}

function toggleType(typeId: number) {
  if (opened.types.includes(typeId)) {
    opened.types = opened.types.filter(id => id !== typeId);
  } else {
    opened.types.push(typeId);
  }
}

function isOpenType(typeId: number): boolean {
  return opened.types.includes(typeId);
}

function productVariant(prodId: number): string {
  return pointFilters.hasFilter('products', prodId) ? 'solid' : 'outline';
}

function toggleProduct(prodId: number) {
  pointFilters.toggleFilter('products', prodId);
}
</script>

<template>
  <div>
    <template
      v-for="type in pointTypes"
      :key="'pt_'+type.id"
    >
      <div class="type-head" @click="toggleType(type.id)">
        <span class="truncate flex-grow" :title="type.title">{{ type.name }}</span>
        <UIcon
          name="i-heroicons-chevron-right-16-solid"
          class="text-xs"
          :class="[isOpenType(type.id) && 'rotate-90']"
        />
      </div>
      <div v-if="isOpenType(type.id)" class="pl-3">
        <template
          v-for="cat in type.productCategories"
          :key="'pc_'+cat.id"
        >
          <div>
            <span class="truncate flex-grow" :title="cat.title">{{ cat.name }}:</span>
          </div>
          <div class="pl-3">
            <UButton
              v-for="prod in cat.products"
              :key="prod.id"
              :title="prod.title"
              size="sm"
              color="blue"
              :variant="productVariant(prod.id)"
              class="mb-1 ml-1"
              @click="toggleProduct(prod.id)"
            >
              {{ prod.name }}
            </UButton>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<style lang="postcss" scoped>
.type-head {
  @apply max-w-full flex items-center
  border-b border-b-gray-200 dark:border-b-gray-700
  bg-blue-100 dark:bg-gray-600
  mb-1 px-2 py-0.5
  cursor-pointer
}
</style>
