<script setup lang="ts">
import type { Ref } from 'vue';
import type { LocationQuery } from 'vue-router';
import { Project } from '~/models';
import {
  useListen,
  usePointCategoryDestroy,
  usePointTrash,
  usePointTypeDestroy,
  useStopListen,
  useTagDelete,
  PROVIDE_PROJECT,
  PROVIDE_VIEW,
} from '#imports';
import type { ViewName } from '~/types';

const { t } = useI18n();
const route = useRoute();
const projectSlug = route.params.projectSlug as string;
const project = useRepo(Project).where('slug', projectSlug).first() as Project;
const view = ref<ViewName>(parseViewFromQuery(route.query));

useSeoMeta({
  title: t('pages.project.title', { name: project.name, view: t(`pages.project.view.${view.value}`) }),
});

provide<Project>(PROVIDE_PROJECT, project);
provide<Ref<ViewName>>(PROVIDE_VIEW, view);

watch(view, (view) => {
  const query = { ...route.query };
  if (view !== 'map') {
    delete query.view;
  } else {
    query.view = 'map';
  }
  useRouter().replace({ query });
});

useListen('view:set', (v) => {
  view.value = v;
});
// types
useListen('type:delete', (typeId: number) => {
  usePointTypeDestroy(project.slug, typeId);
});

// categories
useListen('category:delete', (catId: number) => {
  usePointCategoryDestroy(project.slug, catId);
});
// tags
useListen('tag:delete', (tagId: number) => {
  useTagDelete(project.slug, tagId);
});
// points
useListen('point:trash', (pointId: number) => {
  usePointTrash(project.slug, pointId);
});

onUnmounted(() => {
  useStopListen('view:set');
  // point-type
  useStopListen('type:delete');
  // point-category
  useStopListen('category:delete');
  // tags
  useStopListen('tag:delete');
  // points
  useStopListen('point:trash');
});

function parseViewFromQuery(query: LocationQuery): ViewName {
  if (typeof query.view === 'string' && ['list', 'map'].includes(query.view)) {
    return query.view as ViewName;
  }
  return 'list';
}
</script>

<template>
  <ClientOnly v-if="project">
    <project-filter />
    <lazy-project-point-list v-if="view === 'list'" />
    <lazy-project-map v-else-if="view === 'map'" />

    <lazy-project-editor-type-modal />
    <lazy-project-editor-point-modal />
  </ClientOnly>
</template>
