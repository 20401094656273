import type { IconifyChars, IconifyIcon, IconifyInfo, IconifyJSON, IconifyMetaData } from '@iconify/types';
import { getIconData } from '@iconify/utils/lib/icon-set/get-icon';
import { iconToSVG } from '@iconify/utils/lib/svg/build';
import { type IconifyIconCustomisations } from '@iconify/utils/lib/customisations/defaults';

const iconSets = ['bi', 'heroicons', 'icon-park-solid', 'ic', 'map', 'mdi'];
export const parseIconName = (name: string) => {
  for (const set of iconSets) {
    const parts = name.split(`-${set}-`);
    if (parts.length === 2 && parts[0]) {
      return {
        prefix: parts[0],
        collection: set,
        name: parts[1],
      };
    }
  }
  throw new Error('Undefined icon: ' + name);
};

export type IconsCollection = {
  icons: IconifyJSON,
  info: IconifyInfo,
  metadata: IconifyMetaData,
  chars: IconifyChars
}

export const importCollection = async (collection: string): Promise<IconsCollection> => {
  switch (collection) {
    case 'bi':
      return await import('@iconify-json/bi');
    case 'heroicons':
      return await import('@iconify-json/heroicons');
    case 'icon-park-solid':
      return await import('@iconify-json/icon-park-solid');
    case 'ic':
      return await import('@iconify-json/ic');
    case 'map':
      return await import('@iconify-json/map');
    case 'mdi':
      return await import('@iconify-json/mdi');
  }
  throw new Error('Undefined collection ' + collection);
};

export const getIconSvg = async (iconName: string, customisations?: (icon: IconifyIcon) => IconifyIconCustomisations) => {
  const { prefix, collection, name } = parseIconName(iconName);
  const IconCollection = (await importCollection(collection)).icons;
  const iconData = getIconData(IconCollection, name);
  if (!iconData) {
    throw new Error(`Undefined icon ${name} in collection ${collection}`);
  }
  const svg = iconToSVG(iconData, customisations?.(iconData));
  return `<svg width="${svg.attributes.width}" height="${svg.attributes.height}" viewBox="${svg.attributes.viewBox}">${svg.body}</svg>`;
};
