<script setup lang="ts">
import type { PropType } from 'vue';
import { useUI } from '#ui/composables/useUI';
import { mergeConfig } from '#ui/utils';
import { modal } from '#ui/ui.config';
import { VueFinalModal } from 'vue-final-modal';
import { useModalStack } from '~/composables/modal';

defineSlots<{
  default(props: { isTop: boolean }): void
}>();

const appConfig = useAppConfig();
// @ts-ignore
const config = mergeConfig<typeof modal>(appConfig.ui.strategy, appConfig.ui.modal, modal);

const props = defineProps({
  name: { type: String, required: true },
  ui: { type: Object, default: () => ({}) },
  preventClose: { type: Boolean, default: false },
  transition: { type: Boolean, default: true },
  overlay: { type: Boolean, default: true },
  appear: { type: Boolean, default: false },
  fullscreen: { type: Boolean, default: false },
  class: { type: [String, Object, Array] as PropType<any>, default: () => '' },
});
const isOpen = defineModel<boolean>();
const modalStack = useModalStack();
const opened = computed<boolean>({
  get() {
    return modalStack.isOpen(props.name);
  },
  set(v) {
    if (v) {
      modalStack.open(props.name);
    } else {
      modalStack.close(props.name);
    }
    isOpen.value = v;
  },
});
const isTop = computed<boolean>(() => modalStack.isTop(props.name));

const { ui, attrs } = useUI('modal', toRef(props, 'ui'), config, toRef(props, 'class'));

watch(isOpen, (isOpen) => {
  if (typeof isOpen !== 'undefined') {
    opened.value = isOpen;
  }
}, { immediate: true });

function zIndex() {
  return 60 + modalStack.getIndex(props.name);
}
</script>

<template>
  <VueFinalModal
    v-slot="attr"
    v-model="opened"
    teleport-to="#teleports"
    :modal-id="name"
    :name="name"
    :appear="appear"
    :overlay="overlay"
    :fullscreen="fullscreen"
    :click-to-close="!preventClose"
    :esc-to-close="!preventClose"
    :overlay-transition="transition ? 'vfm-fade' : undefined"
    :content-transition="transition ? 'vfm-fade' : undefined"
    :z-index-fn="zIndex"
    :class="[ui.wrapper]"
    :overlay-class="[ui.overlay.base, ui.overlay.background]"
    :content-class="ui.inner"
  >
    <div :class="[ui.container, !fullscreen && ui.padding]">
      <div
        :class="[
          ui.base,
          ui.background,
          ui.ring,
          ui.shadow,
          fullscreen ? ui.fullscreen : [ui.width, ui.height, ui.rounded, ui.margin],
        ]"
      >
        <slot name="default" :is-top="isTop" v-bind="attr" />
      </div>
    </div>
  </VueFinalModal>
</template>

<style scoped lang="postcss">
</style>
