<script setup lang="ts"></script>

<template>
  <div class="filter-panel">
    <div class="grid grid-cols-12">
      <project-filter-panel-left class="px-2 col-span-12 sm:col-span-3 xl:col-span-2" />
      <project-filter-panel-middle class="px-2 xl:px-3 col-span-12 sm:col-span-6 xl:col-span-8" />
      <project-filter-panel-right class="px-2 col-span-12 sm:col-span-3 xl:col-span-2" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.filter-panel {
  top: calc(100% - 2px);
  max-height: 80dvh;
  @apply absolute
  w-full
  min-h-48
  py-4
  bg-gray-50 dark:bg-gray-800
  text-gray-900 dark:text-white
  rounded-b-md
  border border-gray-300 dark:border-gray-700
  shadow-lg
  overflow-y-auto
}
</style>
