<script setup lang="ts">
import { ProjectFilterInput } from '#components';
import { useModalStack } from '~/composables/modal';

const isOpen = ref(false);
const modalStack = useModalStack();

function openFilters() {
  isOpen.value = true;
  input.value?.focus();
}

function closeFilters(e: Event) {
  const el = e.target as Element;
  if (
    !isOpen.value ||
    el.closest('#points-filter') ||
    el.closest('#headlessui-portal-root') ||
    el.closest('[role="dialog"]') ||
    el.closest('[role="menu"]') ||
    el.closest('.vfm__content') ||
    el.closest('.popper')
  ) {
    return;
  }
  isOpen.value = false;
}

function closeFromShortcut() {
  isOpen.value = false;
  input.value?.blur();
}

const input = ref<typeof ProjectFilterInput | null>(null);
const openShortcut = {
  whenever: [
    () => !isOpen.value,
    () => !modalStack.isOpen(),
  ],
  handler: openFilters,
};
// noinspection JSNonASCIINames
defineShortcuts({
  meta_q: openShortcut,
  meta_й: openShortcut,
  escape: {
    usingInput: true,
    whenever: [
      () => isOpen.value,
      () => !modalStack.isOpen(),
    ],
    handler: closeFromShortcut,
  },
});
onMounted(() => {
  document.addEventListener('click', closeFilters);
});
onBeforeUnmount(() => {
  document.removeEventListener('click', closeFilters);
});
</script>

<template>
  <Teleport to="#top-nav-middle">
    <div id="points-filter" class="relative z-10 sm:min-w-sm md:mx-2 lg:mx-4">
      <project-filter-input ref="input" :is-open="isOpen" @focus="openFilters" />
      <project-filter-panel v-show="isOpen" />
    </div>
  </Teleport>
</template>
