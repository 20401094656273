<script setup lang="ts">
import Popper from 'vue3-popper';

defineSlots<{
  default(): any
  content(props: { isOpen: boolean, close: () => void }): any
}>();
const show = defineModel<boolean | undefined>('show', { default: undefined });
withDefaults(defineProps<{
  content?: string | null
  placement?: 'bottom' | 'auto' | 'auto-start' | 'auto-end' | 'top' | 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end' | 'right' | 'right-start' | 'right-end' | 'left' | 'left-start' | 'left-end'
  disabled?: boolean
  arrow?: boolean
  arrowPadding?: string
  openDelay?: number
  closeDelay?: number
  hover?: boolean
  locked?: boolean
  interactive?: boolean
  disableClickAway?: boolean
  offsetSkid?: string
  offsetDistance?: string
  zIndex?: number
}>(), {
  content: null,
  placement: 'auto',
  disabled: false,
  arrow: false,
  arrowPadding: '0',
  openDelay: 0,
  closeDelay: 0,
  hover: false,
  locked: false,
  interactive: true,
  disableClickAway: false,
  offsetSkid: '0',
  offsetDistance: '12',
  zIndex: 9999,
});
</script>

<template>
  <Popper
    class="my-popper"
    :placement="placement"
    :disable-click-away="disableClickAway"
    :offset-skid="offsetSkid"
    :offset-distance="offsetDistance"
    :arrow="arrow"
    :arrow-padding="arrowPadding"
    :hover="hover"
    :locked="locked"
    :show="show"
    :interactive="interactive"
    :open-delay="openDelay"
    :close-delay="closeDelay"
    :z-index="zIndex"
  >
    <slot />
    <template #content="{isOpen,close}">
      <slot name="content" v-bind="{isOpen,close}">
        {{ content }}
      </slot>
    </template>
  </Popper>
</template>

<style scoped lang="postcss">
.my-popper :deep(.popper) {
  &,
  &:hover,
  &:hover > #arrow,
  &:hover > #arrow::before,
  & > #arrow,
  & > #arrow::before {
    @apply bg-white dark:bg-gray-900
  }
  &,
  &:hover {
    @apply text-gray-900 dark:text-white
  }
  @apply ring-1 ring-gray-200 dark:ring-gray-800
  rounded-md
  shadow-lg
}
</style>
