<script setup lang="ts">
import debounce from 'lodash/debounce';
import type { Ref } from 'vue';
import { UInput } from '#components';
import { usePointFiltersStore, useEmit, PROVIDE_VIEW } from '#imports';
import { PointCategory, PointTag, PointType, Product } from '~/models';
import type { ViewName } from '~/types';

defineProps<{
  isOpen: boolean,
}>();
const view = inject<Ref<ViewName>>(PROVIDE_VIEW);
const emit = defineEmits(['focus']);

const { metaSymbol } = useShortcuts();
const { t } = useI18n();
const pointFilters = usePointFiltersStore();
const typeRepo = useRepo(PointType);
const catRepo = useRepo(PointCategory);
const tagRepo = useRepo(PointTag);
const prodRepo = useRepo(Product);

const colorBtnList = computed(() => view?.value === 'list' ? 'primary' : 'gray');
const colorBtnMap = computed(() => view?.value === 'map' ? 'primary' : 'gray');
const query = computed({
  get: () => pointFilters.filters.q,
  set: debounce((val) => {
    pointFilters.filters.q = val;
  }, 200),
});
type FilterElement = { id: string, name: string, title: string, active: boolean }
const filterElements = computed<FilterElement[]>(() => {
  const res: FilterElement[] = [];
  if (pointFilters.filters.paymentType !== 'any') {
    res.push({
      id: `payment_${pointFilters.filters.paymentType}`,
      name: t(`project.paymentType.${pointFilters.filters.paymentType}`),
      title: '',
      active: true,
    });
  }
  pointFilters.filters.types.forEach((id) => {
    const type = typeRepo.find(id) as PointType;
    res.push({
      id: `type_${type.id}`,
      name: type.name,
      title: type.title,
      active: true,
    });
  });
  pointFilters.filters.categories.forEach((id) => {
    const cat = catRepo.find(id) as PointCategory;
    const isActive = !pointFilters.filters.types.length || pointFilters.filters.types.includes(cat.pointTypeId);
    res.push({
      id: `category_${cat.id}`,
      name: cat.name,
      title: [
        cat.title,
        !isActive && t('project.inactiveFilterCategory'),
      ].filter(Boolean).join('\n'),
      active: isActive,
    });
  });
  pointFilters.filters.tags.forEach((id) => {
    const tag = tagRepo.find(id) as PointTag;
    res.push({
      id: `tag_${tag.id}`,
      name: tag.name,
      title: tag.title,
      active: true,
    });
  });
  pointFilters.filters.products.forEach((id) => {
    const prod = prodRepo
      .with('category')
      .with('fields')
      .find(id) as Product;
    res.push({
      id: `product_${prod.id}`,
      name: prod.name,
      title: [prod.category?.title, prod.title].filter(Boolean).join('\n'),
      active: true,
    });
  });
  return res;
});

const input = ref<typeof UInput | null>(null);

function focus() {
  input.value?.input.focus();
}

function blur() {
  input.value?.input.blur();
}

function removeFilter(filterId: string) {
  const [type, id] = filterId.split('_');
  switch (type) {
    case 'type': {
      pointFilters.toggleFilter('types', +id);
      break;
    }
    case 'category': {
      pointFilters.toggleFilter('categories', +id);
      break;
    }
    case 'tag': {
      pointFilters.toggleFilter('tags', +id);
      break;
    }
    case 'product': {
      pointFilters.toggleFilter('products', +id);
      break;
    }
    case 'payment': {
      pointFilters.setPaymentType('any');
      break;
    }
  }
}

defineExpose({ focus, blur });
</script>

<template>
  <div class="filter-input" :class="[isOpen && '--opened']">
    <UButtonGroup class="mr-2">
      <UButton
        :color="colorBtnList"
        icon="i-heroicons-list-bullet-16-solid"
        :title="$t('project.view.list')"
        @click.stop="useEmit('view:set', 'list')"
      />
      <UButton
        :color="colorBtnMap"
        icon="i-heroicons-map-pin"
        :title="$t('project.view.map')"
        @click.stop="useEmit('view:set', 'map')"
      />
    </UButtonGroup>
    <div class="-mb-1 w-auto">
      <span
        v-for="el in filterElements"
        :key="el.id"
        :title="el.title"
        class="filter-element"
        :class="[!el.active && '--inactive']"
      >
        <span class="truncate">{{ el.name }}</span>
        <UButton
          color="gray"
          variant="link"
          icon="i-bi-x"
          size="xs"
          :padded="false"
          :title="$t('project.removeFilter', {filter:el.name})"
          @click.stop="removeFilter(el.id)"
        />
      </span>
    </div>
    <div class="flex flex-grow items-center">
      <UInput
        ref="input"
        v-model="query"
        autocomplete="off"
        class="flex-grow"
        color="gray"
        variant="none"
        placeholder="..."
        :ui="{ icon: { trailing: { pointer: '' } } }"
        @click="emit('focus')"
      />
      <div class="text-gray-500 text-sm mx-1">
        <UIcon
          v-if="pointFilters.filtering"
          name="i-svg-spinners-eclipse"
          class="animate-spin"
        />
        <span v-else>{{ pointFilters.pointsFiltered.length }}</span>
      </div>
      <div class="flex items-center gap-0.5">
        <UKbd>{{ metaSymbol }}</UKbd>
        <UKbd>Q</UKbd>
      </div>
      <UButton
        v-if="!pointFilters.filtersIsEmpty"
        color="gray"
        variant="link"
        icon="i-heroicons-x-mark-20-solid"
        :padded="false"
        @click="() => pointFilters.clearFilters()"
      />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.filter-input {
  @apply relative
  flex items-center justify-between
  w-full
  disabled:cursor-not-allowed disabled:opacity-75
  focus:outline-none
  border border-gray-300 dark:border-gray-700
  form-input
  rounded-md
  placeholder-gray-400
  dark:placeholder-gray-500
  text-sm
  px-2 py-0.5
  bg-gray-50 dark:bg-gray-800
  text-gray-900 dark:text-white
}

.filter-input.--opened {
  @apply rounded-b-none
}

.filter-element {
  &.--inactive {
    @apply text-gray-400 dark:text-gray-500
    bg-gray-100 dark:bg-gray-800
  }

  @apply rounded inline-flex items-center max-w-md
  py-0.5 px-1
  mr-1 mb-1
  bg-gray-200 dark:bg-gray-600
  border border-gray-300 dark:border-gray-500
}
</style>
