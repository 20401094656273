import BaseAPI from '~/api/_BaseAPI';

export default class PointTypeAPI extends BaseAPI {
  static async destroy(projectSlug: string, pointTypeId: number) {
    const { data } = await this.$delete(`/projects/${projectSlug}/types/${pointTypeId}`);
    return data;
  }

  static async destroyCategory(projectSlug: string, pointTypeId: number, pointCategoryId: number) {
    const { data } = await this.$delete(`/projects/${projectSlug}/types/${pointTypeId}/categories/${pointCategoryId}`);
    return data;
  }

  static async save(projectSlug: string, formData: object) {
    const { data } = await this.$post(`/projects/${projectSlug}/types/save`, formData);
    return data;
  }
}
