import { defineStore } from 'pinia';
export type ModalKey = Symbol | string;
export const useModalStack = defineStore('modals', () => {
  const modals = reactive<ModalKey[]>([]);

  function open(key: ModalKey): void {
    modals.push(key);
  }
  function close(key: ModalKey): void {
    const index = modals.findIndex(k => k === key);
    if (index >= 0) {
      modals.splice(index, 1);
    }
  }
  function isOpen(key?: ModalKey): boolean {
    return key
      ? modals.includes(key)
      : modals.length > 0;
  }
  function getIndex(key: ModalKey): number {
    return modals.findIndex(k => k === key);
  }
  function isTop(key: ModalKey): boolean {
    const index = modals.findIndex(k => k === key);
    if (index >= 0) {
      return index === modals.length - 1;
    }
    return false;
  }

  return {
    open,
    close,
    isOpen,
    isTop,
    getIndex,
  };
});
