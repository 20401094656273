import difference from 'lodash/difference';
import { defineStore } from 'pinia';
import debounce from 'lodash/debounce';
import type { LocationQuery, LocationQueryValue } from 'vue-router';
import { Point, PointCategory, PointFieldValue, ProductPrice, Project } from '~/models';
import { useReplaceUrlSearchParamsWithoutRouter } from '#imports';

export type FilterPaymentType = 'any' | 'cash' | 'cashless';
type PointFilters = {
  q: string
  paymentType: FilterPaymentType
  types: number[],
  categories: number[],
  tags: number[],
  products: number[],
};

export type FilterComplexEntityKey = 'types' | 'categories' | 'tags' | 'products';

const plainFilters = (): PointFilters => ({
  q: '',
  paymentType: 'any',
  types: [],
  categories: [],
  tags: [],
  products: [],
});

const getArrayableValue = (val: LocationQueryValue | LocationQueryValue[] | undefined) => Array.isArray(val) ? val : (val || '').split(',');

const parseFiltersFromQuery = (query: LocationQuery): PointFilters => {
  const filters = plainFilters();
  if (typeof query.q === 'string' && query.q) {
    filters.q = query.q;
  }
  if (query.paymentType === 'cash' || query.paymentType === 'cashless') {
    filters.paymentType = query.paymentType;
  }
  const keys: FilterComplexEntityKey[] = ['types', 'categories', 'tags', 'products'];
  for (const t of keys) {
    const queryValue = getArrayableValue(query[t]);
    if (queryValue.length) {
      for (const id of queryValue) {
        if (id && !isNaN(+id) && !filters[t].includes(+id)) {
          filters[t].push(+id);
        }
      }
    }
  }
  return filters;
};

class SearchAbortedError extends Error {
  override name = 'SearchAbortedError';
}

export const usePointFiltersStore = defineStore('pointFilters', () => {
  const route = useRoute();
  const catRepo = useRepo(PointCategory);
  const pointRepo = useRepo(Point);

  const filtering = ref(false);
  let abortController: null | AbortController = null;
  const filters = reactive(parseFiltersFromQuery(route.query));
  const visiblePointIds = ref<{ [key: number]: boolean }>({
    // [point.id]: true
  });
  watch(filters, debounce((filters) => {
    useReplaceUrlSearchParamsWithoutRouter(filters);
  }, 100), { deep: true });

  const filtersIsEmpty = computed(() => {
    if (filters.q) {
      return false;
    }
    if (filters.paymentType !== 'any') {
      return false;
    }
    const keys: FilterComplexEntityKey[] = ['types', 'categories', 'tags', 'products'];
    for (const t of keys) {
      if (filters[t].length) {
        return false;
      }
    }
    return true;
  });

  const clearFilters = (entity?: keyof PointFilters): void => {
    const plain = plainFilters();
    if (!entity) {
      Object.assign(filters, plain);
    } else {
      Object.assign(filters, {
        [entity]: plain[entity],
      });
    }
  };

  const setPaymentType = (type: FilterPaymentType): void => {
    filters.paymentType = type;
  };

  const hasFilter = (entity: FilterComplexEntityKey, id: number | number[]): boolean => {
    id = Array.isArray(id) ? id : [id];
    return id.every(id => filters[entity].includes(id));
  };
  const toggleFilter = (entity: FilterComplexEntityKey, id: number | number[], value?: boolean): void => {
    const ids = Array.isArray(id) ? id : [id];
    if (typeof value === 'undefined') {
      for (const id of ids) {
        if (hasFilter(entity, id)) {
          filters[entity].splice(filters[entity].findIndex(_id => _id === id), 1);
        } else {
          filters[entity].push(id);
        }
      }
    } else if (value && !hasFilter(entity, ids)) {
      filters[entity].push(...difference(ids, filters[entity]));
    } else if (!value && hasFilter(entity, ids)) {
      for (const id of ids) {
        filters[entity].splice(filters[entity].findIndex(_id => _id === id), 1);
      }
    }
  };

  const project = computed(() => useRepo(Project)
    .where('slug', route.params.projectSlug as string)
    .first() as Project);
  const points = computed(() => {
    return pointRepo
      .where('projectId', project.value.id)
      .with('productPrices', q => q.with('product').orderBy('order'))
      .with('fieldValues')
      .with('deliveryPrices')
      .with('tags', q => q.with('category')) // for filtering only
      .with('type')
      .with('category')
      .with('tagPivots', q => q.orderBy('order'))
      .get();
  });

  watch(filters, (filters) => {
    filtering.value = true;
    if (abortController) {
      abortController.abort();
    }
    (new Promise<{[key: number]: boolean}>((resolve, reject) => {
      abortController = new AbortController();
      abortController.signal.addEventListener('abort', reject);
      const q = filters.q.toLowerCase().trim();
      const res = points.value.reduce((acc, point: Point) => {
        acc[point.id] = filterPoint(q, filters, point);
        return acc;
      }, {} as {[key: number]: boolean});
      resolve(res);
    })).then((res) => {
      visiblePointIds.value = res;
      filtering.value = false;
      abortController = null;
    }).catch((e) => {
      logger().info(e);
    });
  }, { deep: true, immediate: true });
  const pointsFiltered = computed(() => {
    return points.value.filter((p: Point) => visiblePointIds.value[p.id]);
  });

  function filterPoint(q: string, filters: PointFilters, point: Point): boolean {
    if (filters.types.length && !filters.types.includes(point.typeId)) {
      return false;
    }
    if (filters.categories.length) {
      const isBelongedToCategory = filters.categories.some((catId) => {
        const cat = catRepo.find(catId) as PointCategory;
        if (filters.types.length && !filters.types.includes(cat.pointTypeId)) {
          return true;
        }

        return catId === point.categoryId;
      });
      if (!isBelongedToCategory) {
        return false;
      }
    }
    if (filters.tags.length) {
      const hasSelectedTags = filters.tags.some((tagId) => {
        for (const tag of point.tags) {
          if (tagId === tag.id) {
            return true;
          }
        }
        return false;
      });

      if (!hasSelectedTags) {
        return false;
      }
    }

    if (filters.products.length) {
      const hasSelectedProducts = filters.products.some((prodId) => {
        for (const price of point.productPrices) {
          if (price.productId === prodId) {
            return true;
          }
        }
        return false;
      });
      if (!hasSelectedProducts) {
        return false;
      }
    }

    if (filters.paymentType === 'cash') {
      const visible = point.productPrices.some(p => ![null, ''].includes(p.priceCash) && +p.priceCash > 0);
      if (!visible) {
        return false;
      }
    }

    if (filters.paymentType === 'cashless') {
      const visible = point.productPrices.some(p => ![null, ''].includes(p.priceCashless) && +p.priceCashless > 0);
      if (!visible) {
        return false;
      }
    }

    // query
    if (q && q.length > 1) {
      if (
        (!isNaN(+q) && point.id === +q) ||
        (point.name && point.name.toLowerCase().includes(q)) ||
        (point.address && point.address.toLowerCase().includes(q)) ||
        (point.type.name.toLowerCase().includes(q)) ||
        (point.category?.name?.toLowerCase()?.includes(q)) ||
        (point.fieldValues.some(fv => filterQueryFieldValue(q, fv))) ||
        (point.productPrices.some(pp => filterQueryProductPrice(q, pp)))
      ) {
        return true;
      }
      return false;
    }

    return true;
  }

  function filterQueryFieldValue(q: string, fv: PointFieldValue): boolean {
    if (!fv.value) {
      return false;
    }
    if (!String(fv.value).toLowerCase().includes(q)) {
      return false;
    }
    return true;
  }

  function filterQueryProductPrice(q: string, pp: ProductPrice): boolean {
    if (pp.product) {
      if (!pp.product.name.toLowerCase().includes(q)) {
        return false;
      }
      if (pp.product.category && !pp.product.category.name.toLowerCase().includes(q)) {
        return false;
      }
    } else {
      logger().warn(`Point ${pp.pointId} has undefined product ${pp.productId}`);
    }
    return true;
  }

  return {
    // filters
    filtering,
    filters,
    filtersIsEmpty,
    clearFilters,
    setPaymentType,
    hasFilter,
    toggleFilter,
    // points
    points,
    pointsFiltered,
    visiblePointIds,
  };
});
