import { useConfirm } from '#imports';
import { PointTag, type PointTagSettings } from '~/models';
import PointTagAPI from '~/api/PointTagAPI';

export const useTagDelete = async (projectSlug: string, tagId: number) => {
  const repo = useRepo(PointTag);
  const tag = repo.find(tagId);
  if (!tag) {
    logger().warn(`Destroy undefined tag ${tagId}`);
    return;
  }

  const { t } = useNuxtApp().$i18n;
  const confirmed = await useConfirm({ text: t('project.confirm.destroyTag', { name: tag.name }) });
  if (!confirmed) {
    return;
  }
  try {
    tag._deleting = true;
    await PointTagAPI.destroy(projectSlug, tag.id);
    repo.destroy(tag.id);
  } catch (e: any) {
    logger().error(e);
  } finally {
    tag._deleting = false;
  }
};

export const useTagIconStyle = (settings: PointTagSettings): Record<string, any> => {
  const res = {};
  if (settings.textColor) {
    Object.assign(res, { color: settings.textColor });
  }
  if (settings.bgColor) {
    Object.assign(res, { background: settings.bgColor });
  }
  return res;
};
