<script setup lang="ts">
import type { DropdownItem } from '#ui/types';
import { isBackendValidationError, useTagIconStyle } from '#imports';
import { makePointTagSettings, PointTag, PointTagCategory, Project } from '~/models';
import PointTagAPI from '~/api/PointTagAPI';
import type { BackendErrors } from '~/types';

const { t } = useI18n();
const props = defineProps<{
  projectId: number
  canCreateTags: boolean
  canEditTags: boolean
}>();
const emit = defineEmits<{
  saved: [number]
}>();
const tagRepo = useRepo(PointTag);
const tagCatRepo = useRepo(PointTagCategory);

const form = shallowRef();
const formData = ref<PointTag>(new PointTag({
  id: 1,
  settings: makePointTagSettings(),
}));
const state = reactive({
  saving: false,
  opened: false,
});
const ddItems = computed(() => ([
  [
    props.canCreateTags
      ? {
          label: t('tag.add'),
          click: saveTag,
        }
      : null,
    {
      label: t('tag.addCat'),
      click: saveCat,
    },
  ].filter(Boolean) as DropdownItem[],
]));

async function save() {
  if (props.canCreateTags) {
    await saveTag();
  } else {
    await saveCat();
  }
}

async function saveTag() {
  try {
    state.saving = false;
    const project = useRepo(Project).find(props.projectId) as Project;
    const { data } = await PointTagAPI.save(project.slug, formData.value);
    const tag = tagRepo.save(data as object);
    emit('saved', tag.id);
    clear();
  } catch (e: any) {
    if (isBackendValidationError(e)) {
      const errors = e.response.data.errors as BackendErrors;
      setBackendErrors(form.value, errors);
    } else {
      logger().error(e);
    }
  } finally {
    state.saving = false;
  }
}

async function saveCat() {
  try {
    state.saving = false;
    const project = useRepo(Project).find(props.projectId) as Project;
    const { data } = await PointTagAPI.saveCategory(project.slug, formData.value);
    tagCatRepo.save(data as object);
    clear();
  } catch (e: any) {
    if (isBackendValidationError(e)) {
      const errors = e.response.data.errors as BackendErrors;
      setBackendErrors(form.value, errors);
    } else {
      logger().error(e);
    }
  } finally {
    state.saving = false;
  }
}

function clear() {
  formData.value = new PointTag({
    id: 1,
    settings: makePointTagSettings(),
  });
  form.value.clear();
}
</script>

<template>
  <UForm
    ref="form"
    :state="formData"
    :validate-on="['submit']"
    @submit="save"
  >
    <UFormGroup v-if="canCreateTags || canEditTags" name="name">
      <UInput
        v-model="formData.name"
        type="text"
        :disabled="state.saving"
        class="flex-grow"
        :placeholder="$t('tag.new')"
        autocomplete="off"
        :style="useTagIconStyle(formData.settings)"
        :icon="''"
        :ui="{icon:{leading:{pointer:''},trailing:{pointer:''}}}"
        @keydown.enter="save"
      >
        <template v-if="canCreateTags" #leading>
          <project-tag-icon
            v-model="formData.settings"
            :disabled="state.saving"
          />
        </template>
        <template #trailing>
          <UDropdown v-if="formData.name" v-model:open="state.opened" :items="ddItems">
            <UButton
              variant="soft"
              color="gray"
              :loading="state.saving"
              :padded="false"
              icon="i-bi-plus-square"
              class="ml-1"
            />
          </UDropdown>
        </template>
      </UInput>
    </UFormGroup>
  </UForm>
</template>

<style scoped lang="postcss">
</style>
