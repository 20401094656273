<script setup lang="ts">
defineProps<{
  active: boolean
  title?: string
  size?: '2xs' | 'xs' | 'sm' | 'lg'
  hasNested?: boolean
  isOpen?: boolean | null | undefined
}>();
defineSlots<{
  default(): any
  actions(): any
  leading(): any
}>();
const emit = defineEmits(['click', 'toggle']);
const UI = {
  color: {
    white: {
      solid: [
        'ring-1',
        'ring-inset',
        'ring-gray-300',
        'dark:ring-gray-900',
        'text-gray-900',
        'dark:text-white',
        'bg-white',
        'hover:bg-gray-50',
        'disabled:bg-white',
        'dark:bg-gray-900',
        'dark:hover:bg-gray-800/50',
        'dark:disabled:bg-gray-900',
        'focus-visible:ring-2', 'focus-visible:ring-primary-500', 'dark:focus-visible:ring-primary-400',
      ].join(' '),
    },
  },
  variant: {
    outline: [
      'ring-1',
      'ring-inset',
      'ring-{color}-400',
      'dark:ring-{color}-600',
      'text-{color}-500',
      'dark:text-{color}-400',
      'hover:bg-{color}-50',
      'disabled:bg-transparent',
      'dark:hover:bg-{color}-950',
      'dark:disabled:bg-transparent',
      'focus-visible:ring-2',
      'focus-visible:ring-{color}-500',
      'dark:focus-visible:ring-{color}-400',
    ].join(' '),
  },
};
</script>

<template>
  <UButtonGroup :size="size" class="max-w-full">
    <UButton
      :variant="active ? 'solid' : 'outline'"
      :color="active ? 'primary' : 'white'"
      class="filter-tag"
      :title="title"
      :ui="UI"
      @click.stop="emit('click')"
    >
      <template v-if="$slots.leading" #leading>
        <slot name="leading" />
      </template>
      <span class="truncate flex-grow"><slot /></span>
      <div v-if="$slots.actions" class="filter-tag-actions">
        <slot name="actions" />
      </div>
    </UButton>
    <UButton
      v-if="hasNested"
      :variant="active ? 'solid' : 'outline'"
      :color="active ? 'primary' : 'white'"
      class="nested"
      :class="[isOpen && '--opened']"
      icon="i-heroicons-chevron-right-16-solid"
      :ui="UI"
      @click.stop="emit('toggle')"
    />
  </UButtonGroup>
</template>

<style lang="postcss" scoped>
.filter-tag {
  &:hover .filter-tag-actions {
    @apply opacity-100 flex
  }

  @apply max-w-full flex-grow text-left rounded-l-md
}
.nested {
  &.--opened :deep(span) {
    @apply rotate-90
  }
  @apply rounded-r-md
}

.filter-tag-actions {
  @apply gap-1 items-center opacity-0 hidden transition-opacity
}
</style>
