import BaseAPI from '~/api/_BaseAPI';
import type { PointTagSettings } from '~/models';

export type TagSaveRequest = {
  id: number
  categoryId?: null | number
  name: string
  description: null | string
  settings: PointTagSettings
};

export type TagCategorySaveRequest = {
  id: number
  name: string
  description: null | string
}

export type TagsReorderRequest = {
  categories: {
    id: number
    order: number
  }[]
  tags: {
    id: number
    order: number
    categoryId: number|null
  }[]
}

export default class PointTagAPI extends BaseAPI {
  static async save(projectSlug: string, formData: TagSaveRequest) {
    const { data } = await this.$post(`/projects/${projectSlug}/tags/save`, formData);

    return data;
  }

  static async saveCategory(projectSlug: string, formData: TagCategorySaveRequest) {
    const { data } = await this.$post(`/projects/${projectSlug}/tags/categories/save`, formData);

    return data;
  }

  static async destroy(projectSlug: string, tagId: number) {
    const { data } = await this.$delete(`/projects/${projectSlug}/tags/${tagId}`);

    return data;
  }

  static async destroyCategory(projectSlug: string, catId: number, recursive: boolean) {
    const { data } = await this.$delete(`/projects/${projectSlug}/tags/categories/${catId}`, {
      data: {
        recursive,
      },
    });

    return data;
  }

  static async reorder(projectSlug: string, formData: TagsReorderRequest) {
    const { data } = await this.$post(`/projects/${projectSlug}/tags/reorder`, formData);

    return data;
  }
}
