<script setup lang="ts">
import { useTagIconStyle } from '#imports';
import type { PointTagSettings } from '~/models';
const { t } = useI18n();
const settings = defineModel<PointTagSettings>({ required: true });
defineProps({
  disabled: { type: Boolean, default: false },
});
const icon = computed<string>({
  get() {
    return settings.value.icon ? `i-bi-${settings.value.icon}` : '';
  },
  set(v) {
    settings.value.icon = v.substring(5);
  },
});
const tabs = [
  { label: t('tag.icon'), slot: 'icon' },
  { label: t('tag.bgColor'), slot: 'bg' },
  { label: t('tag.textColor'), slot: 'text' },
];
</script>

<template>
  <el-popper :disabled="disabled" arrow placement="auto-start">
    <div class="-ml-1 rounded cursor-pointer" :style="useTagIconStyle(settings)">
      <UIcon
        v-if="icon"
        :name="icon"
        class="my-icon flex-shrink-0 h-5 w-5"
        dynamic
      />
      <span v-else class="px-1 py-0.5">...</span>
    </div>
    <template #content>
      <div class="p-2 min-w-96">
        <UTabs :items="tabs">
          <template #icon>
            <div class="py-1 max-h-64 overflow-y-auto">
              <project-editor-icon-name
                v-model:name="icon"
                :groups="['bi']"
              />
            </div>
          </template>
          <template #bg>
            <div class="max-h-64 overflow-y-auto">
              <project-editor-icon-color
                v-model:color="settings.bgColor"
              />
            </div>
          </template>
          <template #text>
            <div class="max-h-64 overflow-y-auto">
              <project-editor-icon-color
                v-model:color="settings.textColor"
              />
            </div>
          </template>
        </UTabs>
      </div>
    </template>
  </el-popper>
</template>

<style scoped lang="postcss">
</style>
