<script setup lang="ts">
import { PointTag, PointTagCategory, Project } from '~/models';
import { isBackendError, isBackendValidationError } from '#imports';
import PointTagAPI from '~/api/PointTagAPI';
const emit = defineEmits<{
  toggle: [number]
}>();
const props = defineProps<{
  category: PointTagCategory
  canEdit: boolean
  canDelete: boolean
  isOpen: boolean
}>();
const state = reactive({
  hovered: false,
  editing: false,
  saving: false,
  deleting: false,
  destroyConfirmModal: false,
});
const tagsRepo = useRepo(PointTag);
const catsRepo = useRepo(PointTagCategory);
const form = shallowRef();
const formData = reactive({
  name: props.category.name,
  description: props.category.description,
});

const project = computed(() => useRepo(Project).find(props.category.projectId) as Project);

function toggle() {
  emit('toggle', props.category.id);
}

function edit() {
  formData.name = props.category.name;
  formData.description = props.category.description;
  state.editing = true;
}

async function save() {
  try {
    state.saving = true;
    const { data } = await PointTagAPI.saveCategory(project.value.slug, {
      id: props.category.id,
      ...formData,
    });
    catsRepo.save(data as object);
  } catch (e: any) {
    if (isBackendValidationError(e)) {
      setBackendErrors(form.value, e.response.data.errors);
    } else {
      logger().error(e);
    }
  } finally {
    state.saving = false;
    state.editing = false;
  }
}

function tryDestroy() {
  state.destroyConfirmModal = true;
}

async function destroy(recursive: boolean) {
  try {
    state.deleting = true;
    await PointTagAPI.destroyCategory(project.value.slug, props.category.id, recursive);
    state.destroyConfirmModal = false;
    nextTick().then(() => {
      if (recursive) {
        tagsRepo.destroy(tagsRepo.where('categoryId', props.category.id).get().map(tag => tag.id));
      }
      catsRepo.destroy(props.category.id);
    });
  } catch (e: any) {
    if (isBackendError(e)) {
      logger().error(e);
    }
  } finally {
    state.deleting = false;
    state.destroyConfirmModal = false;
  }
}
</script>

<template>
  <div
    class="tag-category"
    @dblclick="toggle"
    @mouseover="state.hovered = true"
    @mouseleave="state.hovered = false"
  >
    <el-popper v-model:show="state.editing" class="tag-category-name" arrow>
      <div class="" :title="category.title">
        {{ category.name }}
      </div>
      <template #content>
        <UForm
          ref="form"
          :state="formData"
          class="bg-white p-2 rounded-md space-y-2"
          @submit="save"
        >
          <UFormGroup name="name">
            <UInput
              v-model="formData.name"
              size="xs"
              autocomplete="off"
              :placeholder="$t('tag.name')"
            />
          </UFormGroup>
          <UFormGroup name="description">
            <UInput
              v-model="formData.description"
              size="xs"
              autocomplete="off"
              :placeholder="$t('tag.desc')"
            />
          </UFormGroup>
          <hr>
          <div class="flex items-center justify-end space-x-1">
            <UButton
              size="xs"
              variant="ghost"
              color="gray"
              @click.stop="state.editing = false"
            >
              {{ $t('project.cancel') }}
            </UButton>
            <UButton
              size="xs"
              icon="i-bi-check"
              :loading="state.saving"
              type="submit"
            >
              {{ $t('project.save') }}
            </UButton>
          </div>
        </UForm>
      </template>
    </el-popper>
    <transition name="tag-category-actions">
      <div v-if="state.hovered" class="tag-category-actions">
        <UButton
          v-if="canEdit"
          :padded="false"
          variant="soft"
          size="2xs"
          color="blue"
          icon="i-heroicons-pencil-square-16-solid"
          :title="$t('project.edit')"
          :disabled="state.editing"
          @click="edit"
        />
        <UButton
          v-if="canEdit"
          :padded="false"
          variant="soft"
          size="2xs"
          color="red"
          icon="i-heroicons-trash"
          :title="$t('project.delete')"
          :disabled="state.saving"
          :loading="state.deleting"
          @click="tryDestroy"
        />
      </div>
    </transition>
    <UButton
      variant="ghost"
      color="gray"
      icon="i-heroicons-chevron-right-16-solid"
      size="xs"
      :class="[isOpen && 'rotate-90']"
      @click.stop="toggle"
    />
    <el-modal
      v-if="state.destroyConfirmModal"
      :model-value="true"
      :transition="false"
      :name="`confirm_remove_tag_category_${category.id}`"
      size="xs"
      :ui="{width: 'w-full sm:max-w-md'}"
    >
      <UCard>
        <div class="space-y-2">
          <strong class="block">{{ $t('tag.destroyCat.confirm') }}</strong>
        </div>
        <template #footer>
          <div class="flex items-center justify-end space-x-1">
            <UButton
              size="sm"
              variant="ghost"
              color="gray"
              @click.stop="state.destroyConfirmModal = false"
            >
              {{ $t('confirm.cancel') }}
            </UButton>
            <UButton
              size="sm"
              icon="i-bi-trash"
              color="red"
              :disabled="state.deleting"
              @click.stop="destroy(false)"
            >
              {{ $t('tag.destroyCat.delete') }}
            </UButton>
            <UButton
              v-if="category.tags.length && canDelete"
              size="sm"
              icon="i-bi-trash"
              color="red"
              :disabled="state.deleting"
              @click.stop="destroy(true)"
            >
              {{ $t('tag.destroyCat.deleteRecursive') }}
            </UButton>
          </div>
        </template>
      </UCard>
    </el-modal>
  </div>
</template>

<style scoped lang="postcss">
.tag-category {
  @apply flex items-center py-0.5 max-w-full
}
.tag-category-name {
  @apply truncate flex-grow
}
.tag-category-actions {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.2s ease;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
  @apply flex items-center
}
</style>
