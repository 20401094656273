<script setup lang="ts">
const color = defineModel<string>('color', { required: true });
const COLORS = [
  [
    'indianRed',
    'lightCoral',
    'salmon',
    'darkSalmon',
    'lightSalmon',
    'crimson',
    'red',
    'fireBrick',
    'darkRed',
  ],
  [
    'pink',
    'lightPink',
    'hotPink',
    'deepPink',
    'mediumVioletRed',
    'paleVioletRed',
  ],
  [
    'lightSalmon',
    'coral',
    'tomato',
    'orangeRed',
    'darkOrange',
    'orange',
  ],
  [
    'gold',
    'yellow',
    'moccasin',
    'peachPuff',
    'paleGoldenrod',
    'khaki',
    'darkKhaki',
  ],
  [
    'lavender',
    'thistle',
    'plum',
    'violet',
    'orchid',
    'fuchsia',
    'magenta',
    'mediumOrchid',
    'mediumPurple',
    'rebeccaPurple',
    'blueViolet',
    'darkViolet',
    'darkOrchid',
    'darkMagenta',
    'purple',
    'indigo',
    'slateBlue',
    'darkSlateBlue',
    'mediumSlateBlue',
  ],
  [
    'greenYellow',
    'chartreuse',
    'lawnGreen',
    'lime',
    'limeGreen',
    'paleGreen',
    'lightGreen',
    'mediumSpringGreen',
    'springGreen',
    'mediumSeaGreen',
    'seaGreen',
    'forestGreen',
    'green',
    'darkGreen',
    'yellowGreen',
    'oliveDrab',
    'olive',
    'darkOliveGreen',
    'mediumAquamarine',
    'darkSeaGreen',
    'lightSeaGreen',
    'darkCyan',
    'teal',
  ],
  [
    'aqua',
    'cyan',
    'lightCyan',
    'paleTurquoise',
    'aquamarine',
    'turquoise',
    'mediumTurquoise',
    'darkTurquoise',
    'cadetBlue',
    'steelBlue',
    'lightSteelBlue',
    'powderBlue',
    'lightBlue',
    'skyBlue',
    'lightSkyBlue',
    'deepSkyBlue',
    'dodgerBlue',
    'cornflowerBlue',
    'mediumSlateBlue',
    'royalBlue',
    'blue',
    'mediumBlue',
    'darkBlue',
    'navy',
    'midnightBlue',
  ],
  [
    'blanchedAlmond',
    'bisque',
    'navajoWhite',
    'wheat',
    'burlyWood',
    'tan',
    'rosyBrown',
    'sandyBrown',
    'goldenrod',
    'darkGoldenrod',
    'peru',
    'chocolate',
    'saddleBrown',
    'sienna',
    'brown',
    'maroon',
  ],
  [
    'lightGray',
    'silver',
    'darkGray',
    'gray',
    'dimGray',
    'lightSlateGray',
    'slateGray',
    'darkSlateGray',
  ],
];
</script>

<template>
  <div>
    <div v-for="group in COLORS" :key="group.join('')" class="color-group">
      <div
        v-for="c in group"
        :key="c"
        class="color-item"
        :class="[c===color && '--active']"
        :style="{background:c}"
        :title="c"
        @click="color=c"
      />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.color-group {
  @apply mb-1 flex flex-wrap
}

.color-item {
  transition: all .2s;
  &.--active {
    transform: scale(1.2);
    @apply border-gray-600 border-2
  }
  &:hover {
    transform: scale(1.2);
  }

  @apply w-6 h-6 rounded border cursor-pointer border-gray-200 mb-0.5 mr-0.5
}
</style>
