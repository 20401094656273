<script setup lang="ts">
import Draggable from 'vuedraggable';
import { isCancel } from 'axios';
import { can } from '~/composables/auth';
import { Ability, PointTag, PointTagCategory, Project } from '~/models';
import { arrayMove } from '~/utils/array';
import PointTagAPI from '~/api/PointTagAPI';
const emit = defineEmits<{
  toggle: [number]
  attach: [number]
  detach: [number]
}>();
const props = defineProps<{
  projectId: number
  attachedTags: number[]
  maxHeight?: number
}>();

const canCreateTags = computed(() => can(Ability.TAGS_CREATE, props.projectId));
const canEditTags = computed(() => can(Ability.TAGS_EDIT, props.projectId));
const canDeleteTags = computed(() => can(Ability.TAGS_DELETE, props.projectId));
const tagRepo = useRepo(PointTag);
const tagCatRepo = useRepo(PointTagCategory);
const tagCategories = computed<PointTagCategory[]>({
  get() {
    return tagCatRepo
      .where('projectId', props.projectId)
      .with('tags', q => q.orderBy('order'))
      .orderBy('order')
      .get();
  },
  set(v) {
    v.forEach((cat, i) => {
      tagCatRepo.save({
        id: cat.id,
        order: i + 1,
      });
    });
    save();
  },
});
const rootTags = computed<PointTag[]>(() => tagRepo
  .where('projectId', props.projectId)
  .where('categoryId', null)
  .orderBy('order')
  .get());

const opened = reactive<number[]>([]);

function catIsOpen(catId: number): boolean {
  return opened.includes(catId);
}

function toggleTagCategory(catId: number) {
  const index = opened.findIndex(id => id === catId);
  if (index >= 0) {
    opened.splice(index, 1);
  } else {
    opened.push(catId);
  }
}

function isAttached(tagId: number): boolean {
  return props.attachedTags.includes(tagId);
}

function toggleTag(tagId: number) {
  emit('toggle', tagId);
  if (isAttached(tagId)) {
    emit('detach', tagId);
  } else {
    emit('attach', tagId);
  }
}

type ChangedObject = {
  moved?: {
    element: PointTag
    newIndex: number
    oldIndex: number
  }
  added?: {
    element: PointTag
    newIndex: number
  }
}

function onChange(categoryId: null | number, e: ChangedObject) {
  const tags = tagRepo.where('categoryId', categoryId).orderBy('order').get();
  if (e.moved) {
    arrayMove(tags, e.moved.oldIndex, e.moved.newIndex);
  } else if (e.added) {
    if (!categoryId || catIsOpen(categoryId)) {
      tags.splice(e.added.newIndex, 0, e.added.element);
    } else {
      tags.push(e.added.element);
      if (categoryId) {
        nextTick().then(() => {
          toggleTagCategory(categoryId);
        });
      }
    }
  } else {
    return;
  }

  tags.forEach((tag: PointTag, index: number) => {
    tagRepo.save({
      id: tag.id,
      categoryId,
      order: index + 1,
    });
  });
  save();
}

let abortController: null | AbortController = null;

async function save() {
  try {
    const project = useRepo(Project).find(props.projectId) as Project;
    if (abortController) {
      abortController.abort();
    }
    abortController = new AbortController();

    await PointTagAPI.reorder(project.slug, {
      categories: tagCatRepo.where('projectId', props.projectId).orderBy('order').get().map(cat => ({
        id: cat.id,
        order: cat.order,
      })),
      tags: tagRepo.where('projectId', props.projectId).orderBy('order').get().map(tag => ({
        id: tag.id,
        order: tag.order,
        categoryId: tag.categoryId,
      })),
    });
  } catch (e) {
    if (!isCancel((e))) {
      logger().error(e);
    }
  } finally {
    abortController = null;
  }
}
</script>

<template>
  <div>
    <div
      class="overflow-y-auto"
      :style="{maxHeight:maxHeight?`${maxHeight}px`:'auto'}"
    >
      <Draggable
        v-model="tagCategories"
        class="tag-categories"
        ghost-class="--draggable"
        handle=".tag-category"
        :disabled="!canEditTags"
        :animation="200"
        :group="{name:'tags-categories',pull:false,put:false}"
        item-key="id"
      >
        <template #item="{element:category}">
          <div class="p-0.5 rounded-md bg-gray-50 px-1">
            <project-tag-category
              :category="category"
              :can-edit="canEditTags"
              :can-delete="canDeleteTags"
              :is-open="catIsOpen(category.id)"
              @toggle="toggleTagCategory"
            />
            <Draggable
              :list="catIsOpen(category.id) ? category.tags : []"
              ghost-class="--draggable"
              :disabled="!canEditTags"
              :animation="200"
              :group="{name:'tags',pull:true,put:['tags']}"
              class="pl-2"
              handle=".point-tag"
              item-key="id"
              @change="e => onChange(category.id, e)"
            >
              <template #item="{element:tag}">
                <project-tag-add-tag
                  :tag="tag"
                  :is-attached="isAttached(tag.id)"
                  :can-edit-tags="canEditTags"
                  :can-delete-tags="canDeleteTags"
                  @toggle="toggleTag(tag.id)"
                />
              </template>
            </Draggable>
          </div>
        </template>
      </Draggable>
      <Draggable
        :list="rootTags"
        class="root-tags"
        ghost-class="--draggable"
        :disabled="!canEditTags"
        :animation="200"
        handle=".point-tag"
        :group="{name:'tags',pull:true,put:['tags']}"
        item-key="id"
        @change="e => onChange(null, e)"
      >
        <template #item="{element:rootTag}">
          <project-tag-add-tag
            :key="rootTag.id"
            :tag="rootTag"
            :is-attached="isAttached(rootTag.id)"
            :can-edit-tags="canEditTags"
            :can-delete-tags="canDeleteTags"
            @toggle="toggleTag(rootTag.id)"
          />
        </template>
      </Draggable>
    </div>
    <project-tag-form
      class="mt-2"
      :project-id="projectId"
      :can-create-tags="canCreateTags"
      :can-edit-tags="canEditTags"
      @saved="toggleTag"
    />
  </div>
</template>

<style scoped lang="postcss">
</style>
