import { useConfirm } from '#imports';
import { PointCategory } from '~/models';
import PointTypeAPI from '~/api/PointTypeAPI';

export const usePointCategoryDestroy = async (projectSlug: string, categoryId: number): Promise<void> => {
  const catRepo = useRepo(PointCategory);
  const cat = catRepo.find(categoryId);
  if (!cat) {
    logger().warn(`Destroy undefined category ${categoryId}`);
    return;
  }
  const { t } = useNuxtApp().$i18n;
  const confirmed = await useConfirm({
    text: t('project.confirm.destroyCategory', { name: cat.name }),
    description: t('project.confirm.destroyCategoryDesc'),
  });
  if (!confirmed) {
    return;
  }

  try {
    cat._deleting = true;
    await PointTypeAPI.destroyCategory(projectSlug, cat.pointTypeId, cat.id);
    catRepo.destroy(cat.id);
  } catch (e: any) {
    logger().error(e);
  } finally {
    cat._deleting = false;
  }
};
