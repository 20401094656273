<script setup lang="ts">
import { Project } from '~/models';
import { usePointFiltersStore } from '#imports';

const project = inject<Project>(PROVIDE_PROJECT) as Project;
const pointFilters = usePointFiltersStore();

</script>

<template>
  <div>
    <project-tag-list
      :project-id="project.id"
      :attached-tags="pointFilters.filters.tags"
      class="w-full"
      @toggle="tagId => pointFilters.toggleFilter('tags', tagId)"
    />
  </div>
</template>

<style lang="postcss" scoped>
</style>
