<script setup lang="ts">
import { Ability, PointCategory, PointType, Project } from '~/models';
import { can, useEmit, usePointFiltersStore } from '#imports';
import type { FilterPaymentType } from '~/composables/point-filters';

const project = inject<Project>(PROVIDE_PROJECT) as Project;

const pointFilters = usePointFiltersStore();
type PointTypeView = {
  id: number
  name: string
  title: string
  canEdit: boolean
  canDelete: boolean
  deleting: boolean
  categories: {
    id: number
    name: string
    title: string
    deleting: boolean
  }[]
};
const pointTypes = computed<PointTypeView[]>(
  () => useRepo(PointType)
    .where('projectId', project.id)
    .with('categories', q => q.orderBy('order'))
    .orderBy('order')
    .get()
    .map((type: PointType) => ({
      id: type.id,
      name: type.name,
      title: [type.name, type.description].filter(Boolean).join('\n'),
      canEdit: can(Ability.TYPES_EDIT, project.id, type.id),
      canDelete: can(Ability.TYPES_DELETE, project.id, type.id),
      deleting: type._deleting,
      categories: type.categories.map((cat: PointCategory) => ({
        id: cat.id,
        name: cat.name,
        title: [cat.name, cat.description].filter(Boolean).join('\n'),
        deleting: cat._deleting,
      })),
    })),
);
const opened = reactive<number[]>([]);
const paymentTypes: FilterPaymentType[] = ['any', 'cash', 'cashless'];

function isOpen(id: number): boolean {
  return opened.includes(id);
}
function toggleOpened(id: number) {
  if (isOpen(id)) {
    opened.splice(opened.findIndex(_id => _id === id), 1);
  } else {
    opened.push(id);
  }
}
</script>

<template>
  <div>
    <UFormGroup :label="$t('project.paymentType.label')" class="mb-2">
      <UButton
        v-for="paymentType in paymentTypes"
        :key="paymentType"
        block
        :variant="paymentType === pointFilters.filters.paymentType ? 'solid' : 'outline'"
        class="mb-1"
        @click="pointFilters.setPaymentType(paymentType)"
      >
        {{ $t(`project.paymentType.${paymentType}`) }}
      </UButton>
    </UFormGroup>
    <div v-for="type in pointTypes" :key="'pointType_'+type.id" class="w-full max-w-full">
      <project-filter-tag
        :active="pointFilters.hasFilter('types',type.id)"
        :title="type.title"
        class="w-full mb-1"
        :has-nested="type.categories.length > 0"
        :is-open="isOpen(type.id)"
        @click="pointFilters.toggleFilter('types',type.id)"
        @toggle="toggleOpened(type.id)"
      >
        {{ type.name }}
        <template #actions>
          <UButton
            v-if="type.canEdit"
            :padded="false"
            variant="soft"
            color="blue"
            size="sm"
            icon="i-heroicons-pencil-square-16-solid"
            :title="$t('project.edit')"
            @click.stop="useEmit('type:edit', {typeId:type.id})"
          />
          <UButton
            v-if="type.canDelete"
            :padded="false"
            variant="soft"
            size="sm"
            color="red"
            icon="i-heroicons-trash"
            :title="$t('project.delete')"
            :loading="type.deleting"
            @click.stop="useEmit('type:delete', type.id)"
          />
        </template>
      </project-filter-tag>
      <div
        v-if="type.categories.length && isOpen(type.id)"
        class="w-full max-w-full pl-2"
      >
        <project-filter-tag
          v-for="cat in type.categories"
          :key="'pointCategory_'+cat.id"
          :active="pointFilters.hasFilter('categories',cat.id)"
          :title="cat.title"
          class="w-full mb-1"
          @click="pointFilters.toggleFilter('categories',cat.id)"
        >
          {{ cat.name }}
          <template #actions>
            <UButton
              v-if="type.canEdit"
              :padded="false"
              variant="soft"
              size="sm"
              color="red"
              icon="i-heroicons-trash"
              :title="$t('project.delete')"
              :loading="cat.deleting"
              @click.stop="useEmit('category:delete', cat.id)"
            />
          </template>
        </project-filter-tag>
      </div>
    </div>
    <UButton
      v-if="can(Ability.TYPES_CREATE, project.id)"
      variant="soft"
      color="primary"
      icon="i-heroicons-plus-16-solid"
      block
      @click.stop="useEmit('type:create')"
    >
      {{ $t('project.addType') }}
    </UButton>
  </div>
</template>
