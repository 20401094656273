import BaseAPI from '~/api/_BaseAPI';

export default class PointAPI extends BaseAPI {
  static async trash(projectSlug: string, pointId: number) {
    const { data } = await this.$delete(`/projects/${projectSlug}/points/${pointId}/trash`);

    return data;
  }

  static async save(projectSlug: string, formData: object) {
    const { data } = await this.$post(`/projects/${projectSlug}/points/save`, formData);

    return data;
  }
}
